<template>
  <div class="news">
    <div class="bread"><i class="icon el-icon-s-unfold" />行业新闻</div>
    <div class="search">
      <el-form ref="dataForm" :model="dataForm">
        <el-form-item
          label="标题："
          class="search-form"
        ><el-input
          v-model="dataForm.title"
          class="input-t"
          clearable
          size="small"
          placeholder="标题"
        /></el-form-item>
        <el-form-item label="新闻分类：" class="search-form">
          <el-select
            v-model="dataForm.dataType"
            size="small"
            placeholder="请选择新闻类型"
          >
            <el-option label="行业新闻" value="1" />
          </el-select>
        </el-form-item>
        <el-form-item label="新闻状态：" class="search-form">
          <el-select
            v-model="dataForm.isStop"
            size="small"
            placeholder="请选择新闻状态"
          >
            <el-option label="启用" :value="false" />
            <el-option label="停用" :value="true" />
          </el-select>
        </el-form-item>
        <el-button
          type="primary"
          size="small"
          class="search-btn"
          @click="searchForm()"
        >查询</el-button>
      </el-form>
    </div>
    <div class="add-line">
      <el-button
        type="primary"
        size="mini"
        @click="addOrUpdateHandle()"
      >+ 新增</el-button>
    </div>
    <div class="tableList">
      <el-table :data="tableData" border style="width: 100%">
        <!-- <el-table-column fixed prop="firstImage" label="首图" width="80px">
          <template slot-scope="scope">
            <img :src="scope.row.firstImage" alt="" style="width:30px;height:30px">
          </template>
        </el-table-column> -->

        <el-table-column fixed prop="title" label="标题" width="500" />
        <el-table-column fixed prop="dataName" label="新闻分类" width="120" />
        <el-table-column fixed prop="source" label="新闻来源" />
        <el-table-column fixed prop="timeAdd" label="创建时间" width="180" />
        <el-table-column fixed="right" label="操作" width="150">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="addOrUpdateHandle(scope.row.newsId)"
            >修改</el-button>
            <el-button
              v-if="scope.row.isStop"
              type="success"
              size="mini"
              @click="HandleEnableDeactivate(scope.row.newsId, 'enable')"
            >启用</el-button>
            <el-button
              v-if="!scope.row.isStop"
              type="danger"
              size="mini"
              @click="HandleEnableDeactivate(scope.row.newsId, 'deactivate')"
            >停用</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page-pagination">
        <el-pagination
          background
          :current-page="currentPage"
          layout="total, prev, pager, next, sizes, jumper"
          :total="total"
          :page-sizes="[10, 20, 50, 100, 300, 500, 1000]"
          :page-size="pageSize"
          prev-text="<"
          next-text=">"
          @size-change="handleSizeChange"
          @current-change="pageChange"
        />
      </div>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-updata ref="addOrUpdate" @refreshDataList="getDataList" />
  </div>
</template>

<script>
import addOrUpdata from '@/components/vbackstage/news/addOrUpdata.vue'
export default {
  name: 'News',
  components: { addOrUpdata },
  data() {
    return {
      msg: '加入我们',
      dataForm: {
        title: '',
        dataType: '1',
        isStop: false
      },
      params: {
        pageIndex: 1,
        pageSize: 10
      },
      total: 0,
      currentPage: 1,
      tableData: [],
      pageSize: 10
    }
  },
  created() {
    this.getDataList()
  },
  methods: {
    // 启用 / 停用
    HandleEnableDeactivate(id, type) {
      let msg = ''
      let url = ''
      let message = ''
      if (type === 'enable') {
        msg = '是否停用?'
        url = '/api/News/StopNews'
        message = '停用成功'
      } else {
        msg = '是否启用?'
        url = 'api/News/OpenNews'
        message = '启用成功'
      }
      this.$confirm(msg, '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.$http.post(`${url}?newsId=${id}`, {}).then((data) => {
            if (data && data.code === 1) {
              this.$message({
                message: message,
                type: 'success',
                duration: 700
              })
              this.getDataList()
            } else {
              this.$message.error(data.msg)
            }
          })
        })
        .catch(() => {
          console.log('取消')
        })
    },
    // 新增 / 修改
    addOrUpdateHandle(data) {
      this.$refs.addOrUpdate.open(data)
    },
    getDataList() {
      const obj = Object.assign({}, this.params, this.dataForm)
      this.$http
        .post('api/News/GetNewsList', obj)
        .then((res) => {
          if (res.code === 1) {
            this.tableData = res.data.dataSource
            this.total = res.data.totalCount
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    searchForm() {
      this.currentPage = 1
      this.params.pageIndex = 1
      this.getDataList()
    },
    pageChange(data) {
      this.currentPage = data
      this.params.pageIndex = data
      this.getDataList()
    },
    handleSizeChange(data) {
      this.params.pageSize = data
      this.searchForm()
    }
  }
}
</script>

<style lang="scss">
@import "./../../assets/scss/backstage.scss";
.news {
  .search-form {
    display: flex;
    margin-right: 40px;
  }
  //  .el-input--suffix .el-input__inner {
  //   height: 32px;
  //   line-height: 32px;
  // }
}
</style>
